import { MultiPillSelectResult } from "src/components/Paradise/MultiPillSelect"
import { ParadiseOverviewExpandable } from "src/components/Paradise/ParadiseOverviewExpandable"
import { ParadiseSettingMultiPillSelect } from "src/components/Paradise/ParadiseSettings/ParadiseSettingMultiPillSelect"
import { ParadiseSettingStatic } from "src/components/Paradise/ParadiseSettings/ParadiseSettingStatic"
import { PillsWrapper } from "src/components/Paradise/sharedStyles"
import { TSettingContainerOnSaveReturnType } from "src/components/Settings/Setting/SettingContainer"
import { usePutParadiseFirmwareTags } from "src/data/paradise/paradiseFirmwares/queries/paradiseFirmwareQueries"
import { IParadiseFirmware } from "src/data/paradise/paradiseFirmwares/types/paradiseFirmwareQueryTypes"
import { MBadge } from "src/ui/MBadge/MBadge"

export function ParadiseFirmwareOverview({
  firmware,
}: {
  firmware: IParadiseFirmware
}) {
  const putFirmwareTags = usePutParadiseFirmwareTags()

  async function handleSaveTags(
    selectedTags: MultiPillSelectResult[]
  ): TSettingContainerOnSaveReturnType {
    try {
      await putFirmwareTags.mutateAsync({
        fwNumber: firmware.name,
        body: {
          tags: selectedTags.map((tag) => tag.id),
        },
      })

      return {
        isSuccess: true,
      }
    } catch (error) {
      return {
        isSuccess: false,
      }
    }
  }

  return (
    <ParadiseOverviewExpandable rawJson={firmware}>
      <ParadiseSettingStatic title="Description" value={firmware.description} />
      <ParadiseSettingStatic title="Location" value={firmware.location_uri} />
      <ParadiseSettingStatic title="Size" value={firmware.size} />
      <ParadiseSettingMultiPillSelect
        title="Tags"
        displayValue={
          firmware.tags.length > 0 ? (
            <PillsWrapper>
              {firmware.tags.map((t) => (
                <MBadge key={t}>{t}</MBadge>
              ))}
            </PillsWrapper>
          ) : (
            "-"
          )
        }
        initialSelected={firmware.tags.map((t) => ({ id: t, name: t }))}
        options={[]}
        onSave={handleSaveTags}
      />
    </ParadiseOverviewExpandable>
  )
}
