import { EmptyState } from "src/components/EmptyState"
import { ParadiseDetailsHeading } from "src/components/Paradise/ParadiseDetailsHeading"
import { ParadiseDetailsLayout } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseFirmwareOverview } from "src/components/Paradise/ParadiseFirmwares/ParadiseFirmwareDetails/ParadiseFirmwareOverview/ParadiseFirmwareOverview"
import { LoadingWrapper } from "src/components/Paradise/sharedStyles"
import { useFetchParadiseFirmware } from "src/data/paradise/paradiseFirmwares/queries/paradiseFirmwareQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import Svg404 from "src/ui/icons/404.svg"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"

export function ParadiseFirmwareDetails({ fwNumber }: { fwNumber: string }) {
  const { t } = useTranslate()

  const fetchFirmware = useFetchParadiseFirmware({
    fwNumber: parseInt(fwNumber),
  })

  if (fetchFirmware.isError) {
    return (
      <EmptyState
        title={t(langKeys.failed_general_error_title)}
        body={t(langKeys.failed_general_error_body)}
        icon={<Svg404 />}
      />
    )
  }

  if (fetchFirmware.isLoading) {
    return (
      <LoadingWrapper>
        <MSkeleton height={40} width="30ch" />
        <MSkeleton height={20} width="40ch" />
      </LoadingWrapper>
    )
  }
  const firmware = fetchFirmware.data.firmware

  return (
    <ParadiseDetailsLayout
      meta={<ParadiseDetailsHeading>{firmware.name}</ParadiseDetailsHeading>}
      left={<ParadiseFirmwareOverview firmware={firmware} />}
      right={<></>}
      breadcrumbs={[
        {
          label: "Firmwares",
          to: Routes.ParadiseFirmwares.location(),
        },
        {
          label: firmware.name.toString(),
          to: Routes.ParadiseFirmware.location(firmware.name.toString()),
        },
      ]}
      breadcrumbCopyValue={firmware.name.toString()}
    />
  )
}
